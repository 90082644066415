export const reactPortfolio = [
    {
        id: 1,
        title: "This website",
        img: "assets/portfolio.png"
    },
    {
        id: 2,
        title: "TBD",
        img: "assets/portfolio.png"
    },
];
export const angularPortfolio = [
    {
        id: 1,
        title: "TBD",
        img: "assets/portfolio.png"
    },
]